import hljs from "highlight.js/lib/core";
import python from "highlight.js/lib/languages/python";
import "highlight.js/styles/night-owl.css";

import Reveal from "reveal.js";
import mermaid from "mermaid";

hljs.registerLanguage("python", python);
hljs.highlightAll();

function initRevealJS() {
  const revealContainers = document.getElementsByClassName("reveal");
  if (revealContainers.length > 0) {
    let config = {
      embedded: true,
      transition: "none",
    };
    if (!revealContainers[0].parentElement.classList.contains("has-keyboard")) {
      config["keyboardCondition"] = "focused";
    }

    deck.initialize(config);
  }
}

let deck = new Reveal();
initRevealJS(deck);

mermaid.initialize({ startOnLoad: true });

document.body.addEventListener("htmx:load", function () {
  hljs.highlightAll();
  initRevealJS(deck);
  mermaid.initialize({ startOnLoad: true });
});
